import React, { useState, useEffect } from "react";
import { Divider, Grid, Button } from "@material-ui/core";
import { useUIContext } from "../../../ContextLib/contextHooks";
import SnackBarHelper from "../Components/Helper/SnackBarHelper";
import MaterialTableSelect from "../Components/Common/MaterialTableSelect";
import { PmsGridSort } from "../Util/PmsHelper";
import MaterialDataGrid from "../Components/Common/MaterialDataGrid";
import {
  GetPmsBuildVersions,
  GetPmsCoverConfigSettingsTemplate,
  GetPmsPropertyConfig,
} from "../Util/pmsIntegrationApiUtil";
import PmsDocumentationJsonTemplate from "./PmsDocumentationJsonTemplate";
import PmsDocumentationModal from "./PmsDocumentationModal";

export default function PmsDocumentation() {
  const ui = useUIContext("v5filters");

  const [showModal, setShowModal] = useState(false);
  const [showModalRow, setShowModalRow] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [selectedRowData, setSelectedRowData] = useState({});
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");

  const [mockData, setMockData] = useState({
    selectedJob: "",
    selectedPms: "",
    selectedVersion: "",
    selectedSelector: "All Properties",
    datadata: [],
    isSelectorReadOnly: true,
  });

  const [settingTemplate, setSettingTemplate] = useState("");
  const [propertyData, setPropertyData] = useState([]);
  const [buildVersions, setBuildVersions] = useState([]);

  const columns = [
    {
      field: "pmsCategory",
      headerName: "PMS",
      width: 110,
      sortComparator: PmsGridSort,
      cellClassName: "sticky-column",
    },
    {
      field: "property",
      headerName: "Property",
      width: 200,
      sortComparator: PmsGridSort,
      cellClassName: "pms-property",
      renderCell: (params) => (
        <label
          variant="contained"
          color="primary"
          size="small"
          onClick={() => onClickColumnProperty(params)}
        >
          {params.value}
        </label>
      ),
    },

    {
      field: "defaultValue",
      headerName: "Default Value",
      width: 450,
    },
    {
      field: "templateDefault",
      headerName: "Template Default",
      width: 400,
    },
    {
      field: "flagDescription",
      headerName: "Flag Description",
      width: 550,
    },
    {
      field: "dataType",
      headerName: "Data Type",
      width: 150,
      sortComparator: PmsGridSort,
    },
    {
      field: "developerUsageOnly",
      headerName: "Dev Only?",
      width: 150,
      sortComparator: PmsGridSort,
      type: "boolean",
    },
  ];

  const onClickColumnProperty = (data) => {
    setShowModalRow(true);
    setSelectedRowData(data.row);
  };
  const HandleUpdateSnackBar = (
    severity,
    message,
    doNotOpenSnackBar = true
  ) => {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);

    if (!doNotOpenSnackBar) setOpenSnackBar(true);
  };

  const _postGetConfigTemplate = async () => {
    if (mockData.selectedPms == "") {
      HandleUpdateSnackBar("error", `Please select a PMS first.`, false);
      return;
    }

    ui.ShowOverlay();
    const promises = [
      GetPmsCoverConfigSettingsTemplate(
        mockData.selectedPms,
        mockData.selectedVersion
      )
        .then((resp) => {
          console.log("te", resp.data);
          const { coverConfigSettingsTemplate } = resp.data;
          if (
            coverConfigSettingsTemplate == null ||
            coverConfigSettingsTemplate == ""
          ) {
            setSettingTemplate("");
            HandleUpdateSnackBar("warning", `No records found`, false);
            return;
          }

          setSettingTemplate(coverConfigSettingsTemplate);
          setShowModal(true);
        })
        .catch((error) =>
          HandleUpdateSnackBar(
            "error",
            `Error while getting the records: ${error}`,
            false
          )
        )
        .finally(() => {
          ui.HideOverlay();
        }),
    ];
    await Promise.all(promises);
  };

  const _postWriteBackSettings = async () => {
    if (mockData.selectedPms == "") {
      HandleUpdateSnackBar("error", `Please select a PMS first.`, false);
      return;
    }

    ui.ShowOverlay();
    const promises = [
      GetPmsPropertyConfig(mockData.selectedPms, mockData.selectedVersion)
        .then((resp) => {
          console.log("te", resp.data.properties);
          if (resp.data.properties == null) {
            setPropertyData([]);
            setMockData({
              ...mockData,
              datadata: [],
            });

            HandleUpdateSnackBar("warning", `No records found`, false);
            return;
          }

          setPropertyData(resp.data.properties);

          let mergedProps = mergeProperties(resp.data.properties);

          setMockData({
            ...mockData,
            datadata: mergedProps,
          });
        })
        .catch((error) =>
          HandleUpdateSnackBar(
            "error",
            `Error while getting the records: ${error}`,
            false
          )
        )
        .finally(() => {
          ui.HideOverlay();
        }),
    ];
    await Promise.all(promises);
  };

  const setValue = (e, isCheckBox = false) => {
    const { name, value, checked } = e.target;
    let val = value;
    if (isCheckBox) val = checked;

    setMockData({
      ...mockData,
      [name]: val,
    });
  };

  function mergeProperties(array) {
    const merged = {};

    array.forEach((item) => {
      const { property, pmsCategory, defaultValue } = item;

      if (!merged[property]) {
        // Initialize if the property hasn't been seen yet
        merged[property] = { ...item };
      } else {
        // Merge default values if "Common"
        if (pmsCategory === "Common") {
          // Combine default values (append or customize merging logic)
          merged[property].defaultValue = combineDefaultValue(merged, item);
          merged[property].templateDefault = combineTemplateValue(merged, item);

          merged[property].flagDescription = combineFlagDescription(
            merged,
            item
          );
        } else {
          // Replace with the non-Common entry
          merged[property] = {
            ...item,
            defaultValue: merged[property].defaultValue + "\n" + defaultValue,
          };
        }
      }
    });

    // Convert merged object back to an array
    return Object.values(merged);
  }
  function combineFlagDescription(merged, item) {
    const { property, defaultValue, flagDescription } = item;

    const firstElement = merged[property].flagDescription;

    const concatLine = "\n\n" + flagDescription;

    return firstElement + concatLine;
  }

  function combineTemplateValue(merged, item) {
    const { property, defaultValue, templateValue } = item;

    let firstElement = "";
    let firstElementVal = "";
    let concatElement = "";
    let concatElementVal = "";

    if (
      merged[property].templateValue == undefined ||
      merged[property].templateValue == ""
    ) {
      //get the defaultValue if templateValue doesn't exist.
      firstElementVal = merged[property].defaultValue;
    } else {
      firstElementVal = merged[property].templateValue;
    }

    console.log("firstElementVal", firstElementVal);
    firstElement = firstElementVal.trim().split("\n").join("\n");

    // if (templateValue == undefined || templateValue == "") {
    //   //get the defaultValue if templateValue doesn't exist.
    //   concatElementVal = defaultValue;
    // } else {
    //   concatElementVal = templateValue;
    // }

    // console.log("concatElementVal", concatElementVal);

    // concatElement =
    //   "\n" +
    //   concatElementVal
    //     .split("\n")
    //     .slice(1) // Removes the first line
    //     .join("\n"); // Joins the lines back together

    return firstElement;
  }

  function combineDefaultValue(merged, item) {
    const { property, defaultValue } = item;

    const firstElement = merged[property].defaultValue
      .trim()
      .split("\n")
      .slice(0, -1)
      .join("\n");

    const concatLine =
      "\n" +
      defaultValue
        .split("\n")
        .slice(1) // Removes the first line
        .join("\n"); // Joins the lines back together

    return firstElement + "," + concatLine;
  }
  useEffect(() => {
    const selector = mockData.selectedSelector.toLowerCase();

    if (selector == "all properties") {
      let mergedProps = mergeProperties(propertyData);
      setMockData({
        ...mockData,
        datadata: mergedProps,
      });
      return;
    }

    if (selector == "common properties") {
      let data = propertyData.filter((n) => n.pmsCategory == "Common");

      setMockData({
        ...mockData,
        datadata: data,
      });
      return;
    }

    let data = propertyData.filter((n) => n.pmsCategory != "Common");

    setMockData({
      ...mockData,
      datadata: data,
    });
    return;
  }, [mockData.selectedSelector]);

  useEffect(() => {
    async function fetchData() {
      ui.ShowOverlay();
      const promises = [
        GetPmsBuildVersions()
          .then((resp) => {
            setBuildVersions(resp.data);
            setMockData({
              ...mockData,
              selectedVersion: resp.data[0],
            });
          })
          .catch((error) =>
            HandleUpdateSnackBar(
              "error",
              `Error while getting the records: ${error}`,
              false
            )
          )
          .finally(() => {
            ui.HideOverlay();
          }),
      ];
      await Promise.all(promises);
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (mockData.selectedPms.toLowerCase() == "common") {
      setMockData({
        ...mockData,
        isSelectorReadOnly: true,
        selectedSelector: "Common Properties",
      });

      return;
    }

    setMockData({
      ...mockData,
      isSelectorReadOnly: false,
      selectedSelector: "All Properties",
    });

    return;
  }, [mockData.selectedPms]);

  const closeModal = () => {
    setSettingTemplate("");
    setShowModal(false);
    setShowModalRow(false);
  };

  return (
    <>
      <PmsDocumentationJsonTemplate
        onCloseModal={() => closeModal()}
        showModal={showModal}
        jsonTemplate={settingTemplate}
      />
      <PmsDocumentationModal
        onCloseModal={() => closeModal()}
        showModal={showModalRow}
        rowValue={selectedRowData}
        // jsonTemplate={settingTemplate}
      />
      <SnackBarHelper
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        Severity={snackBarSeverity}
        AlertMessage={snackBarMessage}
      />
      <h1 className="pmstitle-title__wrapper">
        Blade JSON Config Documentation
      </h1>
      <div className="pms-ws__setting-wrapper">
        <Grid
          container
          alignItems="center"
          spacing={1}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Grid item sm={2}>
            <MaterialTableSelect
              data={[
                "Uprise",
                "Eyefinity",
                "CrystalPM",
                "Officemate",
                "Compulink",
                "Foxfire",
                "Maximeyes",
                "Revolution",
                "NextGen",
              ]}
              label="PMS"
              value={mockData.selectedPms || ""}
              name="selectedPms"
              onChange={(e) => setValue(e)}
              fullWidth={true}
            />
          </Grid>
          <Grid item sm={2}>
            <MaterialTableSelect
              data={buildVersions}
              label="Build Version"
              value={mockData.selectedVersion || ""}
              name="selectedVersion"
              onChange={(e) => setValue(e)}
              fullWidth={true}
            />
          </Grid>
          <Grid item sm={3}>
            <MaterialTableSelect
              data={[
                "All Properties",
                "PMS Specific Properties",
                "Common Properties",
              ]}
              label="Property Selectors"
              value={mockData.selectedSelector || ""}
              name="selectedSelector"
              onChange={(e) => setValue(e)}
              fullWidth={true}
              readOnly={mockData.isSelectorReadOnly}
            />
          </Grid>

          <Grid
            item
            sm={5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              style={{ width: "180px" }}
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => _postWriteBackSettings()}
            >
              Display
            </Button>
            <Button
              style={{ width: "180px", marginLeft: "10px" }}
              type="submit"
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => _postGetConfigTemplate()}
            >
              Show Template
            </Button>
          </Grid>
          <Grid item sm={12}>
            <Divider style={{ margin: "10px 0px" }} />
          </Grid>

          <Grid item sm={12}>
            <MaterialDataGrid
              tableHeight={4500}
              rowHeight={undefined}
              tableColumns={columns}
              tableData={mockData.datadata || []}
              showBorder={true}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
