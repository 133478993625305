const MenuIds = {
  SHADOWCONFIG_LOCATION_MAP: 1701,
  SHADOWCONFIG_PROVIDER_MAP: 1702,
  SHADOWCONFIG_APPOINTMENTREASON_MAP: 1703,
  SHADOWCONFIG_SHADOWMANAGEMENT: 1704,
  SHADOWCONFIG_WEBSCHED_MAP: 1705,
  SHADOWCONFIG_WRITEBACK_SETTING: 1706,
  SHADOWCONFIG_EDEN_WRITEBACK: 1707,
  SHADOWCONFIG_TC_DATAVIEWER: 1708,
  SHADOWCONFIG_BULKUPDATEPAGE: 1709,
  SHADOWCONFIG_PMSHFCONFIGURATIONS: 1710,
  SHADOWCONFIG_PMSDOCUMENTATION: 1711,
};

export default { MenuIds };
