import { Grid, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ShadowMgmtMemberForm from "./ShadowMgmtMemberForm";
import { useUIContext } from "../../../../ContextLib/contextHooks";
import ShadowMgmtMemberModalForm from "./ShadowMgmtMemberModalForm";
import MaterialDialog from "./../../Components/Common/MaterialDialog";
import MaterialDataGrid from "./../../Components/Common/MaterialDataGrid";
import {
  GetJ47ShadowTeam,
  DeleteJ47ShadowTeam,
  UpsertJ47ShadowTeam,
  GetJ47ShadowPMSDetails,
  GetShadowTeamMemberData,
  UpsertShadowMemberConfig,
} from "../../Util/pmsIntegrationShadMgmtApiUtil";
import { PmsGridSort } from "../../Util/PmsHelper";

export default function ShadowMgmtMembers({
  onHandleSnackBar,
  closeShadowMgmt,
  selectedShadowTeam,
  openShadowTeamMemberPage,
}) {
  const ui = useUIContext("v5filters");
  const [showModal, setShowModal] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isActionAdd, setIsActionAdd] = useState(false);
  const [showMemberForm, setShowMemberForm] = useState(false);
  const [listOfPmsDetails, setListOfPmsDetails] = useState([]);
  const [listOfShadowTeams, setListOfShadowTeams] = useState([]);
  const [listOfDicShadowPms, setListOfDicShadowPms] = useState([]);
  const [shadowDbCoverDetail, setShadowDbCoverDetail] = useState([]);
  const [expandLayoutActive, setExpandLayoutActive] = useState(false);
  const [isViewingDataActive, setisViewingDataActive] = useState(true);
  const [listOfShadowDbEngine, setListOfShadowDbEngine] = useState([]);
  const [listOfShadowDataType, setListOfShadowDataType] = useState([]);
  const [selectedShadowMember, setSelectedShadowMember] = useState([]);
  const [listOfShadowDbIpAddress, setListOfShadowDbIpAddress] = useState([]);

  const tableColumns = [
    { field: "coverKey", headerName: "CoverKey", width: 140, type: "number" },
    {
      field: "shadowKey",
      headerName: "ShadowKey",
      width: 135,
      type: "number",
    },
    {
      field: "pms",
      headerName: "PMS",
      width: 195,
      sortComparator: PmsGridSort,
    },
  ];

  useEffect(() => {
    ui.ShowOverlay();
    GetShadowTeamMemberData()
      .then((resp) => {
        const {
          shadowDbIpAddress,
          j47DictShadowPms,
          shadowDbAlias,
          shadowDbDatatypeType,
        } = resp.data;

        const shadowDbEngine = [
          { name: "SQL Server, 1.0", value: "1" },
          { name: "SQL Server, 2.0", value: "2" },
        ];

        setListOfDicShadowPms(j47DictShadowPms);
        setListOfShadowDbIpAddress(shadowDbIpAddress);
        setListOfShadowDbEngine(shadowDbEngine);
        setListOfShadowDataType(shadowDbDatatypeType);
      })
      .catch((error) => {
        onHandleSnackBar(
          "error",
          `Error while fetching GetShadowTeamMemberData records: ${error}`,
          false
        );
      })
      .finally(() => {
        ui.HideOverlay();
      });
  }, []);

  useEffect(() => {
    if (openShadowTeamMemberPage == false) return;
    GetTeamMember();
  }, [openShadowTeamMemberPage]);

  const GetTeamMember = async () => {
    ui.ShowOverlay();
    GetJ47ShadowTeam(selectedShadowTeam.shadowKey)
      .then((resp) => {
        const newData = resp.data.map((obj) => ({
          ...obj,
          id: obj.shadowKey + obj.coverKey,
        }));

        setListOfShadowTeams(newData);
      })
      .catch((error) => {
        onHandleSnackBar(
          "error",
          `Error while fetching GetJ47ShadowTeam records: ${error}`,
          false
        );
      })
      .finally(() => {
        ui.HideOverlay();
      });
  };
  const onHandleShadowMemberchange = (data) => {
    if (selectedShadowMember === data) return;

    if (showMemberForm && !isViewingDataActive) {
      setShowDialog(true);
      return;
    }
    setSelectedShadowMember(data);
    setShowMemberForm(false);
  };

  //this will trigger for every record press, so that the user do not have to press the button the view each of the records.
  useEffect(() => {
    HandleViewRecord(selectedShadowMember.length == 0);
  }, [selectedShadowMember]);

  const handleExpandLayout = () => setExpandLayoutActive(!expandLayoutActive);

  const HandleViewRecord = async (initialLoad = false) => {
    if (!ValidateRowSelected("VIEW", initialLoad)) return;
    setShowMemberForm(true);

    ui.ShowOverlay();

    const promises = [
      GetJ47ShadowPMSDetails(selectedShadowMember.coverKey)
        .then((resp) => {
          setShadowDbCoverDetail(resp.data.shadowDbCoverDetail);
          setListOfPmsDetails(resp.data.j47ShadowPMSDetailList);
        })
        .catch((error) =>
          onHandleSnackBar(
            "error",
            `Error while fetching records on GetJ47ShadowPMSDetails : ${error}`,
            false
          )
        )
        .finally(() => {
          ui.HideOverlay();
        }),
    ];
    await Promise.all(promises);
  };

  const HandleEditRecord = () => {
    if (!ValidateRowSelected("MODIFY")) return;
    setIsActionAdd(false);
    setShowModal(true);
  };

  const _handleDeleteRecord = async () => {
    const { coverKey, shadowKey } = selectedShadowMember;
    if (coverKey === undefined || shadowKey === undefined)
      return onHandleSnackBar(
        "warning",
        "Select a record you want to be DELETED",
        false
      );

    ui.ShowOverlay();
    const promises = [
      DeleteJ47ShadowTeam(coverKey, shadowKey)
        .then((resp) => {
          let newDataSet = [...listOfShadowTeams].filter(
            (n) => n.coverKey != coverKey
          );
          setListOfShadowTeams(newDataSet);

          onHandleSnackBar(
            "success",
            "You have successfully DELETED a record",
            false
          );
        })
        .catch((error) =>
          onHandleSnackBar(
            "error",
            `Error while deleting of records: ${error}`,
            false
          )
        )
        .finally(() => {
          ui.HideOverlay();
        }),
    ];

    await Promise.all(promises);
  };

  const ValidateRowSelected = (action, initialLoad = false) => {
    if (initialLoad) return false;

    if (selectedShadowMember.length === 0) {
      onHandleSnackBar(
        "warning",
        `Please select a record you want to ${action}.`,
        false
      );
      return false;
    }
    return true;
  };

  const _handleAddNewShadowTeam = async (data) => {
    ui.ShowOverlay();
    if (data.shadowKey === "") data.shadowKey = selectedShadowTeam.shadowKey;

    const promises = [
      await GetJ47ShadowTeam(data.coverKey, "coverKey")
        .then(async (resp) => {
          const isCoverExistInShadowKey = resp.data.find(
            (n) => n.shadowKey !== selectedShadowTeam.shadowKey
          );

          if (isCoverExistInShadowKey !== undefined) {
            return onHandleSnackBar(
              "error",
              `This Cover exists under ShadowKey ${isCoverExistInShadowKey.shadowKey}`,
              false
            );
          }

          await _UpsertJ47ShadowTeam(data);
        })
        .catch((error) => {
          onHandleSnackBar("error", `GetJ47ShadowTeam Error: ${error}`, false);
        })
        .finally(() => {
          ui.HideOverlay();
        }),
    ];
    await Promise.all(promises);
  };

  const _UpsertJ47ShadowTeam = async (data) => {
    if (data.shadowKey === "") data.shadowKey = selectedShadowTeam.shadowKey;

    const promises = [
      await UpsertJ47ShadowTeam(data)
        .then((resp) => {
          GetTeamMember();

          const isExist = listOfShadowTeams.find(
            (n) => n.coverKey === resp.data.coverKey
          );

          let msgAction = "ADDED";
          if (isExist) msgAction = "MODIFIED";
          onHandleSnackBar(
            "success",
            `You have successfully ${msgAction} a record`,
            false
          );
          setShowModal(false);
        })
        .catch((error) => {
          onHandleSnackBar(
            "error",
            `UpsertJ47ShadowTeam Error: ${error}`,
            false
          );
        }),
    ];
    await Promise.all(promises);
  };

  const HandleAddRecord = () => {
    setIsActionAdd(true);
    setShowModal(true);
  };

  const IsJsonValid = (json, textName) => {
    if (json === "" || json === undefined) return true;
    try {
      JSON.parse(json);
      return true;
    } catch (e) {
      onHandleSnackBar(
        "error",
        `JSON in textfield of ${textName} is INVALID. Please try again.`,
        false
      );
      return false;
    }
  };

  const _handleSubmitMemberForm = async (data) => {
    if (!IsJsonValid(data.remoteBladeCommands, "RemoteBladeCommand")) return;
    if (!IsJsonValid(data.pmsConnectionString, "PmsConnectionString")) return;

    ui.ShowOverlay();

    if (data.shadowKey === "") data.shadowKey = listOfShadowTeams[0].shadowKey;

    const promises = [
      UpsertShadowMemberConfig(data)
        .then(() => {
          onHandleSnackBar(
            "success",
            `You have successfully MODIFIED a record`,
            false
          );
          setShowModal(false);
        })
        .catch((error) =>
          onHandleSnackBar(
            "error",
            `Error while updating of records: ${error}`,
            false
          )
        )
        .finally(() => {
          ui.HideOverlay();
        }),
    ];
    await Promise.all(promises);
    setisViewingDataActive(true);
  };

  const handleCloseDialog = () => setShowDialog(false);
  return (
    <>
      <div className="shadowMgmt_wrapper member">
        <Grid container spacing={1}>
          {!expandLayoutActive && (
            <>
              <Grid item xs={5} className="landingTbl-txtSearch__wrapper">
                <h1 className="pmstitle-title__wrapper t">
                  Shadow Team Members
                </h1>
                <MaterialDialog
                  open={showDialog}
                  onCloseDialog={handleCloseDialog}
                />
                <ShadowMgmtMemberModalForm
                  showModal={showModal}
                  selectedShadowMember={selectedShadowMember}
                  onCloseModal={setShowModal}
                  isActionAdd={isActionAdd}
                  onAddShadowTeam={_handleAddNewShadowTeam}
                  listOfDicShadowPms={listOfDicShadowPms}
                />
                <MaterialDataGrid
                  // HandleViewRecord={HandleViewRecord}
                  HandleAddRecord={HandleAddRecord}
                  HandleEditRecord={HandleEditRecord}
                  tableColumns={tableColumns}
                  tableData={listOfShadowTeams}
                  onSelectedRow={onHandleShadowMemberchange}
                  pageSize={8}
                  tableHeight={400}
                  HandleDeleteRecord={_handleDeleteRecord}
                  viewToolTipText="View Member Configuration"
                />
                <div className="Shadow-Member-button__container">
                  <Button
                    onClick={() => closeShadowMgmt()}
                    variant="outlined"
                    color="primary"
                  >
                    Back
                  </Button>
                </div>
              </Grid>
            </>
          )}
          <Grid
            item
            xs={expandLayoutActive ? 12 : 7}
            className="landingTbl-txtSearch__wrapper"
          >
            {showMemberForm && (
              <ShadowMgmtMemberForm
                listOfShadowDataType={listOfShadowDataType}
                expandLayoutActive={expandLayoutActive}
                listOfShadowDbEngine={listOfShadowDbEngine}
                listOfShadowDbIpAddress={listOfShadowDbIpAddress}
                listOfPmsDetails={listOfPmsDetails}
                selectedShadowMember={selectedShadowMember}
                shadowDbCoverDetail={shadowDbCoverDetail}
                isViewingDataActive={isViewingDataActive}
                onChangeOfViewingDataActive={setisViewingDataActive}
                onSubmitMemberForm={_handleSubmitMemberForm}
                onExpandLayout={handleExpandLayout}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
