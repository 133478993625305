import clsx from "clsx";
import React from "react";
import ToolTipWithIcon from "./ToolTipWithIcon";
import AddIcon from "@material-ui/icons/NoteAdd";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopy from "@material-ui/icons/FileCopy";
import ViewIcon from "@material-ui/icons/FindInPage";
import RestoreIcon from "@material-ui/icons/RestorePage";
import { DataGrid, GridRow } from "@material-ui/data-grid";

const MaterialDataGrid = ({
  tableData,
  tableColumns,
  HandleViewRecord,
  HandleAddRecord,
  HandleEditRecord,
  HandleDeleteRecord,
  HandleRefreshRecord,
  onSelectedRow,
  tableHeight = 650,
  viewToolTipText = "View Covers in Shadow Team",
  rowHeight = 35,
  checkboxSelection = false,
  GridActions = undefined,
  rowSelectionModel,
  setRowSelectionModel,
  hideFooterSelectedRowCount = true,
  setDataGridState,
  showBorder = false,
}) => {
  const AlternateRowColor = ({ className, index, ...other }) => (
    <GridRow
      index={index}
      className={clsx(className, index % 2 === 0 ? "odd" : "even")}
      {...other}
    />
  );
  const RenderToolBar = () => {
    if (
      HandleViewRecord === undefined &&
      HandleAddRecord === undefined &&
      HandleEditRecord === undefined &&
      HandleRefreshRecord === undefined &&
      HandleDeleteRecord === undefined &&
      GridActions == undefined
    )
      return null;
    return (
      <div className="landingTbl-toolTip__wrapper">
        <span>Actions: </span>
        <div className="landingTbl-toolTip__container">
          {HandleViewRecord !== undefined && (
            <ToolTipWithIcon
              style={{ marginLeft: "10px" }}
              title={viewToolTipText}
              onClick={HandleViewRecord}
              Icon={ViewIcon}
            />
          )}
          {HandleAddRecord !== undefined && (
            <ToolTipWithIcon
              title="Add a New Record"
              onClick={HandleAddRecord}
              Icon={AddIcon}
            />
          )}
          {HandleEditRecord !== undefined && (
            <ToolTipWithIcon
              style
              title="Edit the Selected Record"
              onClick={HandleEditRecord}
              Icon={FileCopy}
            />
          )}
          {HandleRefreshRecord !== undefined && (
            <ToolTipWithIcon
              style
              title="Refresh Records"
              onClick={HandleRefreshRecord}
              Icon={RestoreIcon}
            />
          )}
          {HandleDeleteRecord !== undefined && (
            <ToolTipWithIcon
              style
              title="Delete the Selected Record"
              onClick={HandleDeleteRecord}
              Icon={DeleteIcon}
            />
          )}
          {GridActions !== undefined && GridActions}
        </div>
      </div>
    );
  };

  const HandleonSelectionModelChange = (d) => {
    if (setRowSelectionModel === undefined || setRowSelectionModel === null) {
      return;
    }
    setRowSelectionModel(d);
  };

  const HandleGridState = (d) => {
    if (setDataGridState === undefined || setDataGridState === null) {
      return;
    }
    setDataGridState(d);
  };

  return (
    <>
      {RenderToolBar()}
      <div style={{ height: tableHeight, width: "100%" }}>
        <DataGrid
          pinnedColumns={{ left: ["pmsCategory"] }} // Pin the 'id' column on the left
          rows={tableData}
          rowHeight={rowHeight}
          columns={tableColumns}
          onRowClick={onSelectedRow ? (d) => onSelectedRow(d.row) : undefined}
          components={{ Row: AlternateRowColor }}
          checkboxSelection={checkboxSelection}
          selectionModel={rowSelectionModel}
          hideFooterSelectedRowCount={hideFooterSelectedRowCount}
          onSelectionModelChange={(n) => HandleonSelectionModelChange(n)}
          onStateChange={(n) => HandleGridState(n)}
          showColumnRightBorder={showBorder}
          showCellRightBorder={showBorder}
        />
      </div>
    </>
  );
};

export default MaterialDataGrid;
