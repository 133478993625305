import React from "react";
import { Box, Modal, TextField } from "@material-ui/core";
import TextBoxWithValidator from "../Components/Common/TextBoxWithValidator";
import { ValidatorForm } from "react-material-ui-form-validator";

export default function PmsDocumentationJsonTemplate({
  showModal = false,
  jsonTemplate,
  onCloseModal,
}) {
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => onCloseModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Shadow-modal__wrapper"
      >
        <div className="bulk-confirmation__container pmsmodal_container">
          <div className="bulk-confirmation-grid__wrapper">
            <h2>CoverConfigSettingsTemplate</h2>
            <div className="pms-pmsConnectionString__wrapper">
              <Box
                component="form"
                sx={{ "& .MuiTextField-root": { m: 1 } }}
                noValidate
                autoComplete="off"
              >
                <div className="pms-pmsConnectionString__text-wrapper">
                  {jsonTemplate}
                </div>
              </Box>

              {/* <TextField
                // id="outlined-multiline-static"
                label="CoverConfigSettingsTemplate"
                multiline
                // min={4}
                // defaultValue="Default Value"
                value={jsonTemplate}
                variant="outlined"
              /> */}

              {/* <TextBoxWithValidator
                  required={false}
                  label="CoverConfigSettingsTemplate"
                  multiline={true}
                  rows={2}
                  readOnly={true}
                  value={jsonTemplate || ""}
                  name="pmsConnectionString"
                  onInput={undefined}
                /> */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
